import styled from 'styled-components'

export default styled.label`
  width: 100%;
  font-size: 12px;
  color: #ffffff;
  &.error {
    color: #eb3e42;
  }
  &.disabled {
    color: #3d3d3d;
  }
`
