import React from 'react'
import PropTypes from 'prop-types'

import LabelStyled from './styled'

class Label extends React.PureComponent {
  getClassName() {
    let className = 'label-component'
    className += this.props.className ? ` ${this.props.className}` : ''
    className += this.props.error ? ' error' : ''
    className += this.props.disabled ? ' disabled' : ''
    return className
  }

  render() {
    return (
      <LabelStyled id={this.props.id} data-test={this.props.id} className={this.getClassName()}>
        {this.props.text}
      </LabelStyled>
    )
  }
}

Label.defaultProps = {
  id: 'label',
  text: '',
  error: false,
  disabled: false,
}

Label.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Label
